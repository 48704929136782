import DOMUtils from '@livongo/utilities/system/dom';

const ApptentiveUtils = {
    async init({appId, options = {}} = {}) {
        await DOMUtils.loadScript({
            src: `https://sdk.apptentive.com/v1/apps/${appId}/websdk`,
        });

        window.ApptentiveSDK.createConversation(options);
    },

    identify({publicUUID, customData}) {
        if (!window.ApptentiveSDK) {
            return;
        }

        window.ApptentiveSDK.identifyPerson({
            /* eslint-disable camelcase */
            unique_token: publicUUID,
            ...(customData && {custom_data: customData}),
            /* eslint-enable camelcase */
        });
    },

    event(eventName) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.engage(eventName);
    },

    updatePerson(person) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.updatePerson(person);
    },

    setLocale(locale) {
        if (!window.ApptentiveSDK) {
            return;
        }

        if (!window.ApptentiveSDK.conversation) {
            return;
        }

        window.ApptentiveSDK.setLocale(locale);
    },

    /**
     * Traps focus within a specified element, ensuring that focus cycles through
     * the focusable elements within the element when using the Tab key.
     *
     * @param {HTMLElement} element - The container element within which to trap focus.
     */
    trapFocus(element) {
        if (!element) return;

        try {
            // Get all focusable elements within the trap container, these are WCAG tabable elements
            const focusableElements = Array.from(
                element.querySelectorAll(
                    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
                )
            ).filter(
                // filters out element has display = none
                e =>
                    e.offsetParent !== null &&
                    window.getComputedStyle(e).display !== 'none'
            );

            if (focusableElements.length === 0) {
                return;
            }

            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement =
                focusableElements[focusableElements.length - 1];

            // Prevents focus from leaving the modal
            element.addEventListener('keydown', event => {
                if (event.key === 'Tab') {
                    if (event.shiftKey) {
                        if (document.activeElement === firstFocusableElement) {
                            event.preventDefault();
                            lastFocusableElement.focus();
                        }
                    } else {
                        if (document.activeElement === lastFocusableElement) {
                            event.preventDefault();
                            firstFocusableElement.focus();
                        }
                    }
                }
            });

            // Focus the first element when the trap is activated
            firstFocusableElement.focus();
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Apptentive:trapFocus:', e);
        }
    },

    /**
     * Delays the display of a modal element by a specified amount of time.
     * Usage: Call this to ensure the modal is displayed last and prevent the component from using LazyLoad to steal the focus.
     *
     * @param {HTMLElement} element - The DOM element to be displayed.
     * @param {number} delay - The delay in seconds before displaying the element.
     * @param {Function} callback - The callback function to be executed after the delay.
     */
    delayModalDisplay(element, delay, callback) {
        if (!element) return;

        element.style.display = 'none';
        setTimeout(() => {
            element.style.display = 'inline-flex';
            element.tabIndex = 0;
            element.focus();
            callback();
        }, delay * 1000); // Convert delay to second
    },

    /**
     * Handles the focus for a given modal element with an optional delay.
     *
     * @param {HTMLElement} modalElement - The modal element to focus.
     * @param {number} delay - The delay in second before focusing and showing the modal element.
     */
    handleFocus(modalElement, delay = 3 /* default second of delay */) {
        if (modalElement) {
            try {
                this.delayModalDisplay(modalElement, delay, () => {
                    this.trapFocus(modalElement);
                });
            } catch (err) {
                // eslint-disable-next-line no-console
                console.log('Apptentive:FocusError:', err);
            }
        }
    },

    /**
     * Finds an element in the document based on the given Apptentive event type.
     *
     * The event type is expected to be in the format 'prefix:suffix'
     * e.g. apptentive:survey:launch
     * where the function will use the suffix part to query the document
     * for an element with a tag name 'apptentive-suffix',
     * e.g. apptentive-survey
     *
     * @param {string} eventType - The event type string in the format 'prefix:suffix'.
     * @returns {Element|null} The found element or null if no element is found.
     */
    findElementFromEventType(eventType /* e.g. apptentive:survey:launch */) {
        // find apptentive-survey
        return document.querySelector(`apptentive-${eventType.split(':')[1]}`);
    },
};

export default ApptentiveUtils;
