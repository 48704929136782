const REDIRECT_GOTO = process.env.REDIRECT_GOTO;

export const IS_PROD = process.env.ENVIRONMENT === 'production';
export const IS_NON_PROD = process.env.ENVIRONMENT !== 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const REGISTER_LINK = process.env.REGISTER_URL;
export const SIGNUP_LINK = process.env.SIGNUP_URL;
export const TDOC_AUTH_TOKEN = 'member_auth_token';
export const MEAL_TYPE = 'meal_type';
export const MEAL_ID = 'meal_id';
export const FOOD_UI = 'food_ui';
export const CACHED_DATA_MINUTES_TTL = 5;
export const DATA_LOAD_EXPIRATION = 'data_load_expiration';
export const INITIAL_DATA_LOAD = 'initial_data_load';
export const ONEAPP_HOME_PATH = '/';
export const MEDOPT_SIGNUP = 'medopt-signup';
export const MEDOPT_WM_DPP_SIGNUP = 'condition-care-WM-DPP-signup';
export const MY_REFRESH_COOKIE = 'my_refreshToken';
export const ACCESS_TOKEN_COOKIE = 'access_token';
export const REFRESH_TOKEN_COOKIE = 'refresh_token';
export const EDUCATION_TOKEN_COOKIE = 'education-token';
export const FOOD_TOUR_KEY = 'has-seen-food-tour';
export const NEXT_COACHING_SESSION = 'next-coaching-session';
export const COACH_SESSION = 'coach-session';
export const SEND_MESSAGE_ONCE = 'send-message-once';
export const ONBOARDING_INTRO_SEEN = 'has-seen-onboarding-intro';
export const ACHIEVED_WEIGHT_COOKIE = 'has-seen-achieved-weight';
export const HF_RETAKE_ALERT_ID = 'retake-alert-id';
export const MEMBER_ID = 'member_id';
export const MEMBER_ID_PREV = 'member_id_prev';
export const IMPERSONATION = 'impersonation';
export const HF_RETAKE_REACHOUT_NOT_NEEDED_KEY =
    'has-seen-retake-reachout-not-needed-card';
export const HF_RETAKE_LINK_TIMED_OUT_KEY = 'has-seen-retake-link-timeout';
export const USER_LOCALE_COOKIE = 'user_preferred_language';
export const LANGUAGE_MODAL_KEY = 'has-seen-language-modal';
export const UTM_PARAMETERS = 'utm_parameters';
export const KEYCLOAK_URI = 'keycloak_uri';
export const MEMBER_COMMUNICATION_POLICY = {
    'en-US':
        'https://content.livongo.com/docs/Livongo_Member_Communication_Policy.pdf',
    'es-US':
        'https://content.livongo.com/docs/Livongo_Member_Communication_Policy.pdf',
    // TODO: update to Spanish Member Communication Policy once it's translated
};
export const MEMBER_SUPPORT_EMAIL = 'membersupport@livongo.com';
export const ONEAPP_SUPPORT_EMAIL = 'membersupport@teladochealth.com';
export const INTRO_DPP_VIDEO_URL = {
    'en-US': `${REDIRECT_GOTO}/dpp-intro-video?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/dpp-intro-video?locale=es-US`,
};
export const INTRO_DM_VIDEO_URL = {
    'en-US': `${REDIRECT_GOTO}/dm-intro-video?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/dm-intro-video?locale=es-US`,
};
export const INTRO_HTN_VIDEO_URL = {
    'en-US': `${REDIRECT_GOTO}/htn-intro-video?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/htn-intro-video?locale=es-US`,
};
export const MEMBER_SUPPORT_URL = {
    'en-US':
        'https://livongo.zendesk.com/hc/en-us/categories/115001480808-Member-Self-Help-Center',
    'es-US':
        'https://livongo.zendesk.com/hc/es-us/categories/115001480808-Member-Self-Help-Center',
};
export const ZENDESK_URL = {
    'en-US': 'https://livongo.zendesk.com/hc/en-us',
    'es-US': 'https://livongo.zendesk.com/hc/es-us',
};
export const TWILIO_SMS_PHONE_NUMBER = '844-981-4079';
export const IOS_APP_LINK = 'https://apps.apple.com/us/app/livongo/id991595088';
export const ANDROID_APP_LINK =
    'https://play.google.com/store/apps/details?id=com.livongo.lvmobility&hl=en_US';
export const IOS_ONEAPP_LINK =
    'https://apps.apple.com/us/app/teladoc-telehealth-therapy/id656872607';
export const ANDROID_ONEAPP_LINK =
    'https://play.google.com/store/apps/details?id=com.teladoc.members&hl=en_US&gl=US';
export const TERMS_OF_SERVICE_URL = {
    'en-US': `${REDIRECT_GOTO}/terms-of-service?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/terms-of-service?locale=es-US`,
};
export const PRIVACY_POLICY_URL = {
    'en-US': `${REDIRECT_GOTO}/web-privacy-policy?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/web-privacy-policy?locale=es-US`,
    // TODO: Uncomment spanish privacy policy link after document is updated at the end of q1 2021
    // es: 'https://teladochealth.com/es/privacy-policy/',
};
export const CONSENT_AGREEMENT_URL =
    'https://www.teladoc.com/informed-consent-agreement';
export const PRIVACY_PRACTICES_URL = {
    'en-US': `${REDIRECT_GOTO}/web-privacy-practice?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/web-privacy-practice?locale=es-US`,
};
export const COPA_PRIVACY_URL = `${REDIRECT_GOTO}/children-online-privacy`;
export const NAME_INPUT_PATTERN = "^(?![- '.]+$)[-0-9A-zÀ-ž '.]*$"; // https://regex101.com/r/1D9Myt/97
export const ADDRESS_INPUT_PATTERN = '[-.0-9A-zÀ-ž #]+';
export const EMAIL_INPUT_MAX_LENGTH = 100;
export const EMAIL_INPUT_PATTERN =
    '^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$';
export const CONFETTI_COLORS = [
    '#0060FC',
    '#36D663',
    '#86D5DF',
    '#66B5F7',
    '#E80048',
    '#34A847',
    '#E47600',
    '#845EF7',
    '#149DB1',
];
export const PHONE_INPUT_PATTERN =
    '^[(]?([0-9]{3})[)]?([-. ])?([0-9]{3})([-. ])?([0-9]{4})$';
export const FORMAT_DATETIME_NO_TZ = "yyyy-MM-dd'T'HH:mm:ss";
export const REFERRER_REGEX = /^https:\/\/(\w*\.)*livongo\.com\//;
export const BANNER_LAUNCH_DATE = {month: 4, day: 15};
export const BANNER_EXPIRY_DATE = {month: 5, day: 30};
export const NON_DISCRIMINATION_URL = {
    'en-US': `${REDIRECT_GOTO}/notice-of-non-discrimination?locale=en-US`,
    'es-US': `${REDIRECT_GOTO}/notice-of-non-discrimination?locale=es-US`,
};
export const CONDITION_MANAGEMENT_HOMEPATH = '/condition-management/home';
export const BP_USING_YOUR_MONITOR = '/blood-pressure/using-your-monitor';
export const WEIGHT_GET_STARTED = '/weight/get-started';
export const ONEAPP_BP_USING_YOUR_MONITOR =
    '/condition-management/blood-pressure/using-your-monitor';
export const ONEAPP_WEIGHT_GET_STARTED =
    '/condition-management/weight/get-started';
export const ONEAPP_PROFILE_SETTINGS_PATH = '/account/profile';
export const EDUCATION_SOURCE_PARAM = '&source=member-portal';
export const FOOD_INSIGHTS_PATH = '/food/overview'; // Food Insights features
export const MEMBER_SUPPORT_PHONE_NUMBER = '1-800-835-2352';
export const LIVONGO_HOME_PAGE = 'https://www.livongo.com/';
export const CHOOSE_LIVONGO_HOME = 'https://choose.livongo.com';
export const STAGES_OF_KIDNEY_DISEASE_LINK = {
    'en-US':
        'https://library.teladochealth.com/hc/en-us/articles/360060708393-Kidney-Health-Basics-Stages-of-Kidney-Disease',
    'es-US':
        'https://library.teladochealth.com/hc/es-us/articles/360060708393-Kidney-Health-Basics-Stages-of-Kidney-Disease',
};
export const UNDERSTANDING_KIDNEY_RESULTS_LINK = {
    'en-US':
        'https://library.teladochealth.com/hc/en-us/articles/1500000408222-Kidney-Health-Basics-Understanding-Your-Results',
    'es-US':
        'https://library.teladochealth.com/hc/es-us/articles/1500000408222-Kidney-Health-Basics-Understanding-Your-Results',
};
export const MANAGING_CHRONIC_KIDNEY_DISEASE = {
    'en-US':
        'https://library.teladochealth.com/hc/en-us/sections/1500000312641-Managing-Chronic-Kidney-Disease',
    'es-US':
        'https://library.teladochealth.com/hc/es-us/sections/1500000312641-Managing-Chronic-Kidney-Disease',
};
export const TEAMS_APP_ID_URL = 'https://teams.microsoft.com/l/entity';
export const DESKTOP_SCREEN_SIZE = 1025;
export const MOBILE_SCREEN_SIZE = 767;
export const FEATURE_FLAG_NAMES = {
    mhWebsdk: 'enable_mh_websdk',
    clientMigration: 'enable_client_migration',
    onboarding: 'enable_onboarding',
    medoptpbc: 'enable_medoptpbc',
    healthEquity: 'enable_healthequity',
    launchpad: 'enable_health_journey_launchpad',
    reminder: 'enable_reminders',
    mhGuide: 'enable_guide_feature',
    enablenewcoachingUi: 'enable_new_coaching',
    enableGlpEducation: 'enable_glp_education',
    enableNewUpsell: 'enable_new_upsell',
    goal: 'enable_goal_setting',
    enablenewfoodUi: 'enable_new_foodLogger',
    newCardOrder: 'enable_new_card_order',
};
export const EVENT_PROPS = {
    Global: 'Global',
    Languages: 'Languages',
    LanguageOptions: {
        'en-US': 'English',
        'es-US': 'Spanish',
    },
};
export const HEALTH_EQUITY_FIELDS = [
    'race',
    'sexualOrientation',
    'genderIdentity',
    'pronouns',
];
export const CORE_PROGRAMS = {
    DM: 'DIABETES',
    HTN: 'HYPERTENSION',
    WM: 'WEIGHT_MANAGEMENT',
    DPP: 'PREDIABETES',
    BH: 'BEHAVIORAL_HEALTH',
    CKD: 'CHRONIC_KIDNEY_DISEASE',
    HF: 'HEART_FAILURE',
    DF: 'DIABETES_DEVICEFLEX',
};

export const RACE = 'race';
export const PRONOUNS = 'pronouns';
export const GENDER_IDENTITY = 'genderIdentity';
export const SEXUAL_ORIENTATION = 'sexualOrientation';
export const DK = 'DK';
export const PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY';
export const PROGRAM_MATERIALS_EMAIL = 'optIn';
export const PROGRAM_MATERIALS_SMS = 'smsOptOut';
export const sessionUUID = 'session_uuid';
export const YOUR_KEY = 'yours';
export const NETWORK_STATUS = 'networkStatus';
export const AVAILABLE_KEY = 'available';
export const BEHAVIORAL_HEALTH_SLUG = 'behavioralHealth';
export const REMINDER_FREQUENCY = [
    {name: 'DAY', checked: true},
    {name: 'WEEK', checked: false},
    {name: 'MONTH', checked: false},
];
export const DAYS_OF_THE_WEEK = [
    {name: 'SUNDAY', checked: true},
    {name: 'MONDAY', checked: true},
    {name: 'TUESDAY', checked: true},
    {name: 'WEDNESDAY', checked: true},
    {name: 'THURSDAY', checked: true},
    {name: 'FRIDAY', checked: true},
    {name: 'SATURDAY', checked: true},
];
export const DELIVERY_METHODS = {
    sms: 'SMS',
    pushNotification: 'PUSH_NOTIFICATION',
};
export const CONSENT_STATUS = {
    prompt: 'PROMPT',
    in: 'IN',
    out: 'OUT',
};
export const MIXPANEL_PRODUCT = 'Chronic Condition Management';
export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const WEEKDAY = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const APPTENTIVE_EVENTS = [
    'apptentive:survey:launch',
    'apptentive:note:launch',
    'apptentive:message-center:launch',
    'apptentive:love-dialog:launch',
];

export const COACHING_TYPES = {
    INVALID: 0,
    INTRO: 1,
    FOLLOW_UP: 2,
    OTHER: 3,
    INITIAL: 4,
};
export const COACHING_METHODS = {
    INVALID: 0,
    PHONE: 1,
    VIDEO: 2,
    OTHER: 3,
};

export const TIMEZONES = {
    US: [
        {
            abbreviation: 'HT',
            timeZoneCode: 'US/Hawaii',
            timeZoneDesc: 'US/Hawaii',
            timeZoneId: 368,
            longName: 'Hawaii-Aleutian Daylight Time',
        },
        {
            abbreviation: 'AK',
            timeZoneCode: 'US/Alaska',
            timeZoneDesc: 'US/Alaska',
            timeZoneId: 362,
            longName: 'Alaska Daylight Time',
        },
        {
            abbreviation: 'PT',
            timeZoneCode: 'US/Pacific',
            timeZoneDesc: 'US/Pacific',
            timeZoneId: 372,
            longName: 'Pacific Daylight Time',
        },
        {
            abbreviation: 'MT',
            timeZoneCode: 'US/Mountain',
            timeZoneDesc: 'US/Mountain',
            timeZoneId: 371,
            longName: 'Mountain Daylight Time',
        },
        {
            abbreviation: 'CT',
            timeZoneCode: 'US/Central',
            timeZoneDesc: 'US/Central',
            timeZoneId: 365,
            longName: 'Central Daylight Time',
        },
        {
            abbreviation: 'ET',
            timeZoneCode: 'US/Eastern',
            timeZoneDesc: 'US/Eastern',
            timeZoneId: 367,
            longName: 'Eastern Daylight Time',
        },
    ],
};
